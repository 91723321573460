
#root, #app {
  height: 100%;
}
.ant-layout {
  height: 100%;
  overflow: auto;
}

.main-menu {
  box-shadow: 0 0 1px 1px grey;

  li {
    height: 60px !important; 
  }
  .ant-menu-title-content {
    font-size: 1.6vh;
  }
  .ant-layout-sider-children {
    padding-top: 75px;
  }
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
}


.ant-affix {
  z-index: 11;
}

.ant-anchor-link-title {
  white-space: break-spaces !important;
}