@import '~antd/dist/antd.less'; 

body {
  font-size: 1.4vh;
}

.light-theme {
  --primary: #02475e; 
  --secondary: #194350;
  --primaryBackground: #F4F6F6;
  --secondaryBackground: #dae2e6;
  --menuColor: rgb(50	38	68);
  --fontColor: rgb(38	38	38);
}
.dark-theme {
  --primary: #f9f3f3;
  --secondary:#dddddd;
  --primaryBackground: #121212;
  --secondaryBackground: #121212;
  --menuColor: rgb(50	38	68);
  --fontColor: rgba(255, 255, 255, 0.6);
}



.ant-menu, .ant-layout-sider-children {
  background: var(--primaryBackground) !important;
}
.ant-layout, .ant-layout-header {
  background: var(--secondaryBackground) !important;
}
.ant-typography {
  color: var(--fontColor) !important;
}
.main-menu .ant-menu-item {
  background: none !important;
}


.main-menu li, .main-menu li a {
  color: var(--fontColor) !important;  
}

.main-menu li.ant-menu-item-selected {
  color: var(--primary) !important;  
  font-weight: bold;
}




// background: #121212;
// font-family: .AppleSystemUIFont;
// font-size: 20px;
// color: rgba(255,255,255,0.60);
// letter-spacing: 0.15px;
// line-height: 24px;
// font-family: .AppleSystemUIFont;
// font-size: 20px;
// color: rgba(255,255,255,0.60);
// letter-spacing: 0.15px;
// text-align: right;
// line-height: 24px;


// background: #121212;
// box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20);
// font-family: .AppleSystemUIFont;
// font-size: 20px;
// color: rgba(255,255,255,0.60);
// letter-spacing: 0.15px;
// text-align: right;
// line-height: 24px;
// font-family: .AppleSystemUIFont;
// font-size: 20px;
// color: rgba(255,255,255,0.60);
// letter-spacing: 0.15px;
// line-height: 24px;